import React, { useEffect, useState } from "react";
import "../styles/pages/profile.css";
import edit from "../assets/images/profile/edit.svg";
import delete_img from "../assets/images/icons/delete.png";
import TextField from "@mui/material/TextField";
import { Grid, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "../styles/components/profile_details.css";
import "../styles/components/order_transaction.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import addmore from "../assets/images/icons/add_more.svg";
import { authInstance } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Address() {
  const [showForm, setShowForm] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [fullName, setFullName] = useState(localStorage.getItem("fullName") || ""); // pre-fill from localStorage
  const [mobileNumber, setMobileNumber] = useState(localStorage.getItem("mobileNumber") || ""); // pre-fill from localStorage
  const [email, setEmail] = useState(localStorage.getItem("email") || ""); // pre-fill from localStorage
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [addressType, setAddressType] = useState("");
  const [error, setError] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const id = localStorage.getItem("vedauserid");

  // Fetch addresses from API
  const fetchAddresses = async () => {
    try {
      const response = await authInstance.get(`customer/address/${id}`);
      setAddresses(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleAddAddressClick = () => {
    setShowForm(!showForm);
  };

  const handleEditAddress = async (addressId) => {
    const addressToEdit = addresses.find((address) => address.id === addressId);
    if (addressToEdit) {
      setFullName(addressToEdit.full_name || fullName); // fallback to already filled personal info
      setMobileNumber(addressToEdit.phone_no || mobileNumber);
      setWhatsappNo(addressToEdit.whatsup_no || whatsappNo);
      setEmail(addressToEdit.email || email);
      setAddressLine1(addressToEdit.address1 || "");
      setAddressLine2(addressToEdit.address2 || "");
      setPinCode(addressToEdit.pincode || "");
      setAddressType(addressToEdit.address_type || "");
      setSelectedCountry(addressToEdit.country || "");
      setSelectedState(addressToEdit.state || "");
      setSelectedCity(addressToEdit.city || "");

      setShowForm(true);
      setEditingAddressId(addressId);
    }
  };

  const handleSaveAddress = async () => {
    try {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email && !emailPattern.test(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }

      const phonePattern = /^\d{10}$/;
      if (mobileNumber && !phonePattern.test(mobileNumber)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      if (whatsappNo && !phonePattern.test(whatsappNo)) {
        toast.error("Please enter a valid 10-digit WhatsApp number.");
        return;
      }

      const pincodePattern = /^[1-9][0-9]{5}$/;
      if (pinCode && !pincodePattern.test(pinCode)) {
        toast.error("Please enter a valid pincode.");
        return;
      }

      const dataToSend = {
        full_name: fullName || null,
        phone_no: mobileNumber || null,
        whatsup_no: whatsappNo || null,
        email: email || null,
        address1: addressLine1 || null,
        address2: addressLine2 || null,
        pincode: pinCode || null,
        address_type: addressType || null,
        city: selectedCity || null,
        state: selectedState || null,
        country: selectedCountry || null,
      };

      let response;
      if (editingAddressId) {
        response = await authInstance.patch(
          `customer/address/${editingAddressId}`,
          dataToSend
        );
      } else {
        response = await authInstance.post("customer/address", dataToSend);
      }

      toast.success("Address saved successfully.");
      setShowForm(false);
      fetchAddresses();
      clearForm();
      setError("");
    } catch (error) {
      console.error("Error saving/updating address:", error);
      toast.error("Error saving address. Please try again later.");
    }
  };

  const clearForm = () => {
    setFullName("");
    setMobileNumber("");
    setEmail("");
    setAddressLine1("");
    setAddressLine2("");
    setPinCode("");
    setWhatsappNo("");
    setAddressType("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
  };

  const handleDelete = async (addressId) => {
    try {
      await authInstance.delete(`customer/address/delete/${addressId}`);
      toast.success("Address deleted successfully");
      fetchAddresses();
    } catch (error) {
      console.error(`Error deleting Address with ID ${addressId}:`, error);
      toast.error("Error deleting Address");
    }
  };

  return (
    <>
      <div className="">
        <div className="add_member">
          <div className="">
            <p>Add Address</p>
          </div>
          <div className="add_more">
            <Button
              disableRipple
              disableElevation
              onClick={handleAddAddressClick}
            >
              Add
              <img src={addmore} />
            </Button>
          </div>
        </div>
        {showForm && (
          <div className="userprofile_right_bg">
            <div className="personal-info profile-adress">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="fullName"
                    label="Full Name *"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="mobileNumber"
                    label="Mobile Number *"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="email"
                    label="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="pinCode"
                    label="Pin Code *"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine1"
                    label="Address Line 1 *"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine2"
                    label="Address Line 2 *"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="country"
                    label="Country *"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="state"
                    label="State *"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="city"
                    label="City *"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel id="addressType-label">
                      Address Type
                    </InputLabel>
                    <Select
                      labelId="addressType-label"
                      id="addressType-select"
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      variant="outlined"
                      label="Address Type"
                    >
                      <MenuItem value="">Select Address Type</MenuItem>
                      <MenuItem value="Home">Home</MenuItem>
                      <MenuItem value="Work">Work</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="whatsappNo"
                    label="Alternate No *"
                    value={whatsappNo}
                    onChange={(e) => setWhatsappNo(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="rest_save_button">
                    <Button
                      className="rest"
                      disableRipple
                      disableElevation
                      onClick={() => {
                        setShowForm(false);
                        clearForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="save"
                      disableRipple
                      disableElevation
                      onClick={() => handleSaveAddress()}
                    >
                      Save Address
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        <div className="family-scroll">
          {addresses.map((address) => (
            <div className="save_info_details" key={address.id}>
              <div className="person_save_info">
                <h3>{address.full_name}</h3>
                <div className="edit-delete">
                  <div className="edit_button">
                    <Button
                      disableRipple
                      disableElevation
                      onClick={() => handleEditAddress(address.id)}
                    >
                      Edit
                      <img src={edit} alt="Edit" />
                    </Button>
                  </div>
                  <div className="edit_button">
                    <Button
                      disableRipple
                      disableElevation
                      onClick={() => handleDelete(address.id)}
                    >
                      Delete
                      <img src={delete_img} alt="Edit" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="relation_rashi_details_main">
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Full Name</h4>
                    <p>{address.full_name}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Mobile Number</h4>
                    <p>{address.phone_no}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Email</h4>
                    <p>{address.email || "N/A"}</p>
                  </div>
                </div>
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>City</h4>
                    <p>{address.city}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>State</h4>
                    <p>{address.state}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Country</h4>
                    <p>{address.country}</p>
                  </div>
                </div>
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Address Line 1</h4>
                    <p>{address.address1}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Address Line 2</h4>
                    <p>{address.address2 || "N/A"}</p>
                  </div>
                </div>
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Pincode</h4>
                    <p>{address.pincode}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>WhatsApp Number</h4>
                    <p>{address.whatsup_no || "N/A"}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Address Type</h4>
                    <p>{address.address_type}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Address;
