import React, { useEffect, useRef, useState } from "react";
import "../styles/pages/check_out.css";
import "../styles/pages/seva_checkout.css";
import Header from "../layouts/Header";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Button,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  DialogActions,
  Paper,
} from "@mui/material";
import { Container } from "@mui/material";
import mycard from "../assets/images/check_out/mycart/card.svg";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Ganga_Jaal from "../assets/images/check_out/mycart/image-01.png";
import Mala from "../assets/images/check_out/mycart/image-02.png";
import Locket from "../assets/images/check_out/mycart/image-03.png";
import left_sm_arrow from "../assets/images/check_out/mycart/left-sm-arrow.svg";
import right_symbol from "../assets/images/check_out/mycart/right-symbol.svg";
import right_sm_arrow from "../assets/images/check_out/mycart/right-sm-arrow.svg";
import Footer from "../layouts/Footer";
import Checkbox from "@mui/material/Checkbox";
import edit from "../assets/images/profile/edit.svg";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import instance, { authInstance } from "../utils/api";
import { useAppContext } from "../components/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Seva_booking_successful from "../components/Seva_booking_successful";
import logoIcon from "../assets/images/logo/icon.png";
import { CloseOutlined } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import whatsapp from "../assets/images/icons/whatsapp.png";
import GTM from "../utils/gtm";
import AddressEdit from "../components/AddressEdit";
import AddFamily from "../components/AddFamily";
import axios from "axios";

function Check_out() {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailprofile, setEmailprofile] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [isIndian, setIsIndian] = useState("indian");
  const {
    setCustomerName,
    setCustomerPhone,
    thankYouseva,
    setThankYouseva,
    setIsLoggedIn,
  } = useAppContext();
  const vedatoken = localStorage.getItem("vedatoken");
  const id = localStorage.getItem("vedauserid");
  const [activeStep, setActiveStep] = useState(0);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen2(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [pujaData, setPujaData] = useState([]);
  const [familyPersons, setFamilyPersons] = useState([]);
  const [wantPrasadam, setWantPrasadam] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [tokenExpire, setTokenExpire] = useState(false);
  const options = { maximumFractionDigits: 2 };
  const [sameForBillingAddressChecked, setSameForBillingAddressChecked] =
    useState(true);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [error, setError] = useState({});
  const handleIndianChange = (event) => {
    setIsIndian(event.target.value === "indian");
  };
  const [proceedNext, setProceedNext] = useState(true);
  const [expandedFormId, setExpandedFormId] = useState();
  const [expandedFamily, setExpandedFamily] = useState();
  const [address, setAddress] = useState("");

  const updateEmailProfile = async (email) => {
    const trimmedEmail = email.trim(); // Remove spaces
    setEmailprofile(trimmedEmail);

    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    if (!emailRegex.test(trimmedEmail)) {
      setError((prev) => ({ ...prev, email: true }));
      setProceedNext(true);
    } else {
      setError((prev) => ({ ...prev, email: false }));
      if (firstName && lastName && mobileNo) {
        setProceedNext(false);
      }
    }
  };

  const updateName = async (name, type) => {
    if (type === "first") setFirstName(name);
    if (type === "last") setLastName(name);
    const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
    if (!nameRegex.test(name)) {
      setError((prev) => {
        return { ...prev, [type]: true };
      });
      setProceedNext(true);
      return false;
    } else {
      setError((prev) => {
        return { ...prev, [type]: false };
      });
      setProceedNext(false);
    }
  };

  const updateMobile = async (number) => {
    setMobileNo(number);
    const nameRegex = /^\d{10}$/;
    if (!nameRegex.test(number)) {
      setError((prev) => {
        return { ...prev, mobile: true };
      });
      setProceedNext(true);
      return false;
    } else {
      setError((prev) => {
        return { ...prev, mobile: false };
      });
      setProceedNext(false);
    }
  };
  const handleindiaClick = () => {
    if (isIndian === null) {
      // Prompt user to select nationality
      console.log("Please select nationality");
      return;
    }
    // Proceed with other logic
  };
  const handleprasadam = () => {
    if (wantPrasadam) {
      setWantPrasadam(false);
    } else {
      setWantPrasadam(true);
    }
    // Proceed with other logic
  };

  const handleAddFamilyMember = () => {
    setOpen2(true);
    setModalMode("add");
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchProfileData();
  }, []);

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);

      const exp = decoded.exp;
      const now = Math.floor(Date.now() / 1000);

      return exp < now;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    const expired = isTokenExpired(vedatoken);
    setTokenExpire(expired);
    console.log(expired);
    if (expired === true) {
      toast.error("Session expired. Please Login again.");
      setIsLoggedIn(false);
      localStorage.clear();
      navigate("/");
    }
  }, [vedatoken]);
  const fetchLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const city = response.data.city || "";
      const state = response.data.region || "";
      setAddress(`${city}, ${state}`); // Combine city and state
      console.log("Fetched Address:", `${city}, ${state}`); // Log the address
    } catch (error) {
      console.error("Error fetching IP location:", error);
    }
  };
  const fetchProfileData = async () => {
    try {
      const response = await authInstance.get(`customer/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${vedatoken}`,
        },
      });
      const profileData = response.data;

      setFirstName(profileData.user.first_name || "");
      setLastName(profileData.user.last_name || "");
      setEmailprofile(profileData.user.email || "");
      setMobileNo(profileData.user.username || "");
      setCustomerName(
        `${profileData.user.first_name} ${profileData.user.last_name}`
      );
      setCustomerPhone(profileData.user.username);

      // Enable Next button if all required fields are filled
      if (
        profileData.user.first_name &&
        profileData.user.last_name &&
        profileData.user.username
      ) {
        setProceedNext(false);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const saveProfileData = async () => {
    try {
      let updatedProfileData = {
        user: {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: emailprofile.trim(),
        },
      };

      if (emailprofile) {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        if (!emailRegex.test(emailprofile)) {
          toast.error("Please enter a valid email address");
          return;
        }
      }

      if (firstName && lastName) {
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!firstName.match(nameRegex) || !lastName.match(nameRegex)) {
          toast.error("Name should contain only alphabets");
          return;
        }
      }
      const response = await authInstance.put(
        `customer/${id}/`,
        updatedProfileData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);

      if (response.status === 200) {
        console.log("Updated profile data:", response.data);
        setIsEditing(false);
        fetchProfileData();
      } else {
        console.error("Failed to update profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };

  // Define your steps as an array of labels.
  const steps = ["Step 1", "Step 2"];

  // const handleStepClick = (step) => () => {
  //   saveProfileData();
  //   console.log(firstName);
  //   if (step === activeStep + 1) {
  //     if (activeStep === 0) {
  //       if (!firstName || !lastName || !mobileNo) {
  //         return;
  //       }
  //     } else if (activeStep === 1) {
  //       if (selectedFamilyMembers.length === 0) {
  //         return;
  //       }
  //     }
  //   }
  //   setActiveStep(step);
  // };
  // Function to handle step navigation click
  const handleStepClick = (step) => () => {
    saveProfileData();

    if (step === activeStep + 1) {
      if (
        activeStep === 0 &&
        !sameForBillingAddressChecked &&
        (selectedAddressId === null || selectedAddressId1 === null)
      ) {
        if (selectedAddressId === null || selectedAddressId1 === null) {
          toast.error("Please select a Billing address");
        } else if (!sameForBillingAddressChecked)
          toast.error("Please select a Billing address");
        return; // Prevent navigation if required fields are not filled
      }
      if (!firstName || !lastName || !mobileNo) {
        toast.error("Please fill all required personal fields");
        return;
      }
      if (activeStep === 1 && selectedFamilyMembers.length === 0) {
        toast.error("Please select at least 1 family member");
        return; // Prevent navigation if no family members are selected
      }
    }
    setActiveStep(step);
  };

  const [age, setAge] = React.useState("");

  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  const { sevaprice_id } = useParams();
  const { seva_id } = useParams();
  const { slug } = useParams();
  console.log(slug);
  const handleRemoveClick = () => {
    navigate("/pujas/" + slug);
  };
  // console.log({ sevaprice_id, seva_id, slug });
  const [selectedPuja, setSelectedPuja] = useState(null);
  const [selectedSeva, setSelectedSeva] = useState(null);
  const [selectedSevaPrice, setSelectedSevaPrice] = useState(null);
  const [sevafor, setSevaFor] = useState();
  const [puja_id, setPujaId] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(`puja/${slug}`);
        setSelectedPuja(response.data);
        const matchingSeva = response.data.sevas.find(
          (seva) => seva.seva.id === parseInt(seva_id)
        );
        if (response.data.donation_expired === true) {
          navigate("/");
        }
        setPujaId(response.data.row_pre_id);

        // console.log(matchingSeva);

        // console.log(sevafor);
        if (matchingSeva) {
          setWantPrasadam(matchingSeva.seva.prasad_available);
          setSelectedSeva(matchingSeva);
          console.log(selectedSeva);
          const matchingSevaType = matchingSeva.seva.seva_type.find(
            (type) => type.id === parseInt(sevaprice_id)
          );

          console.log(matchingSevaType);
          setSevaFor(matchingSevaType.seva_for);
          if (matchingSevaType) {
            setSelectedSevaPrice(matchingSevaType.price);
            if (matchingSeva.seva.service_charge) {
              let serviceamount =
                (matchingSevaType.price / 100) *
                matchingSeva.seva.service_charge;

              setSelectedAmount(serviceamount);
            } else {
              setSelectedAmount(0);
            }

            console.log(selectedSevaPrice);
          } else {
            console.log("Selected seva price not found");
          }
        } else {
          console.log("Selected seva not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug, seva_id, sevaprice_id]);

  const [expire, setExpire] = useState(false);

  const fetchExpire = async () => {
    try {
      const response = await instance.get(`puja/${slug}`);
      console.log(response.data.donation_expired);
      if (response.data.donation_expired === true) {
        setExpire(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log(selectedSevaPrice);
  }, [selectedSevaPrice]);

  const [dakshanaOptions, setDakshanaOptions] = useState([]);
  const [selectedDakshana, setSelectedDakshana] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(0);

  useEffect(() => {
    const fetchDakshina = async () => {
      try {
        // const response = await instance.get("puja/seva/dakshana");
        const response = await instance.get("puja/seva/dakshana_active");
        const data = response.data;
        setDakshanaOptions(data);

        const defaultOption = data.find((option) => option.is_default);
        if (defaultOption) {
          setSelectedDakshana(defaultOption.value);

          if (defaultOption.value === "other") {
            // setSelectedAmount(customAmount);
          } else {
            // setSelectedAmount(defaultOption.value);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDakshina();
  }, [selectedSevaPrice]);

  // const handleChangeDaskshana = (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedDakshana(selectedValue);

  //   if (selectedValue === "other") {
  //     const defaultOption = dakshanaOptions.find((option) => option.is_default);
  //     if (defaultOption) {
  //       setSelectedAmount(defaultOption.value);
  //       setCustomAmount(defaultOption.value);
  //     }
  //   } else {
  //     setSelectedAmount(selectedValue);
  //   }
  // };

  const [customAmountChecked, setCustomAmountChecked] = useState(false);

  const handleCustomAmountCheckChange = (event) => {
    const isChecked = event.target.checked;
    setCustomAmountChecked(isChecked);

    if (isChecked) {
      const defaultOption = dakshanaOptions.find((option) => option.is_default);
      if (defaultOption) {
        setSelectedAmount(defaultOption.value);
        setCustomAmount(defaultOption.value);
      }
    } else {
      setCustomAmount("");
      setSelectedDakshana("");
    }
  };

  const handleCustomAmountChange = (event) => {
    const enteredAmount = event.target.value;

    if (!isNaN(enteredAmount)) {
      const amount = parseFloat(enteredAmount);

      if (amount >= 0) {
        setCustomAmount(amount);
        setSelectedAmount(amount);
      } else {
        setCustomAmount(0);
        setSelectedAmount(0);
      }
    } else {
      setCustomAmount(0);
      setSelectedAmount(0);
    }
  };

  const handleClick = () => {
    if (selectedFamilyMembers.length === 0) {
      alert("Please select at least 1 family member");
    } else {
      handleNextClick();
    }
  };
  const handleNextClick = () => {
    if (!firstName || !lastName || !mobileNo) {
      toast.error("Please fill all required personal fields");
      return;
    }
    if (activeStep == 0) {
      saveProfileData();
    }
    setActiveStep((prevStep) => prevStep + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling behavior
    });
  };

  const handleprevClick = () => {
    setActiveStep((prevStep) => prevStep - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling behavior
    });
  };
  const handleSlugBack = () => {
    navigate(`/pujas/${slug}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling behavior
    });
  };

  const [expanded, setExpanded] = useState(true);

  const handleExpansion = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {}, []);

  const fetchFamilyPersonDetails = async () => {
    try {
      console.log(sevafor);
      if (sevafor) {
        const response = await authInstance.get(
          `customer/family_detail?family_type=${sevafor}`
        );
        setFamilyPersons(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching person details:", error);
    }
  };
  useEffect(() => {
    fetchFamilyPersonDetails();
  }, [sevafor, expandedFamily]);

  const [addresses, setAddresses] = useState([]);

  const [showModalAddress, setShowModalAddress] = useState(false);
  const [modalMode1, setModalMode1] = useState("add");
  const handleModalOutsideClick = (event) => {
    if (event.target.className === "modal1") {
      setShowModalAddress(false);
    }
  };
  const handleModal1OutsideClick = (event) => {
    if (event.target.className === "modal") {
      setShowModal(false);
    }
  };
  const handleAddAddress = () => {
    setOpen(true);
    // clearForm();
    setModalMode1("add");
  };
  const fetchAddresses = async () => {
    try {
      const response = await authInstance.get("customer/address");
      const addressList = response.data;
      setAddresses(addressList);

      // Automatically check the most recent address
      if (addressList.length > 0) {
        const mostRecentAddress = addressList[addressList.length - 1]; // Get the last address
        setSelectedAddressId(mostRecentAddress.id); // Set the most recent address as selected by default
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [expandedFormId]);

  useEffect(() => {
    if (addresses.length > 0) {
      // Automatically check the most recent address if available
      const mostRecentAddress = addresses[addresses.length - 1]; // Get the last address
      setSelectedAddressId(mostRecentAddress.id); // Set the most recent address as selected by default
    } else {
      setSelectedAddressId(null); // Handle when no addresses are available
    }
  }, [addresses]);

  // const fetchCountries = async () => {
  //   try {
  //     const response = await instance.get("master/countries/");
  //     setCountries(response.data.results);
  //   } catch (error) {
  //     console.error("Error fetching countries:", error);
  //   }
  // };

  // const fetchStates = async (countryId) => {
  //   try {
  //     const response = await instance.get(
  //       `master/states/?country=${countryId}`
  //     );
  //     setStates(response.data.results);
  //   } catch (error) {
  //     console.error("Error fetching states:", error);
  //   }
  // };

  // const fetchCities = async (stateId) => {
  //   try {
  //     const response = await instance.get(`master/cities/?state=${stateId}`);
  //     setCities(response.data.results);
  //   } catch (error) {
  //     console.error("Error fetching cities:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  // const handleChangeCountry = (event) => {
  //   const countryId = event.target.value;
  //   setSelectedCountry(countryId);
  //   setSelectedState("");
  //   setSelectedCity("");
  //   if (countryId) {
  //     fetchStates(countryId);
  //   }
  // };

  // const handleChangeState = (event) => {
  //   const stateId = event.target.value;
  //   setSelectedState(stateId);
  //   setSelectedCity("");
  //   if (stateId) {
  //     fetchCities(stateId);
  //   }
  // };

  // const handleChangeCity = (event) => {
  //   setSelectedCity(event.target.value);
  // };

  //   const handleAddAddressClick = () => {
  //     setShowForm(!showForm);
  //   };

  // const clearForm = () => {
  //   setFullName("");
  //   // setMobileNumber("");
  //   setEmail("");
  //   setAddressLine1("");
  //   setAddressLine2("");
  //   setPinCode("");
  //   setWhatsappNo("");
  //   setAddressType("");
  //   setSelectedCountry("");
  //   setSelectedState("");
  //   setSelectedCity("");
  //   setShowModalAddress(false);
  // };
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  console.log(selectedAddressId);
  const handleFamilyMemberSelection = (e, person) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedFamilyMembers([...selectedFamilyMembers, person]);
    } else {
      setSelectedFamilyMembers(
        selectedFamilyMembers.filter((p) => p.id !== person.id)
      );
    }
  };
  const [selectedAddressId1, setSelectedAddressId1] = useState(null);
  const handleAddressSelection = (addressId) => {
    if (selectedAddressId === addressId) {
      // Deselect the currently selected address if clicked again
      setSelectedAddressId(null);
      setSameForBillingAddressChecked(false); // Uncheck billing if no address selected
    } else {
      // Select the new address and uncheck any previously selected ones
      setSelectedAddressId(addressId);
      setSameForBillingAddressChecked(true); // Automatically check the billing address checkbox
    }
  };
  const handleAddressSelection1 = (addressId) => {
    // alert(addressId)
    setSelectedAddressId1(selectedAddressId1 === addressId ? null : addressId);
  };
  const handleAddressAddition = (newAddress) => {
    // Deselect any previously selected address
    setSelectedAddressId(null);

    // Set the new address as the selected one
    setSelectedAddressId(newAddress.id);

    // Check the "Same for Billing Address" checkbox as the new address is now selected
    setSameForBillingAddressChecked(true);

    // Add the new address to the addresses array
    setAddresses((prevAddresses) => [...prevAddresses, newAddress]);
  };
  const handleSubmitNewAddress = async (newAddressData) => {
    try {
      // Assuming you're making an API call to add a new address
      const response = await authInstance.post(
        "customer/address/",
        newAddressData
      );

      // Once the address is successfully added, handle the selection of the new address
      const newAddress = response.data;
      handleAddressAddition(newAddress);
    } catch (error) {
      console.error("Error adding new address:", error);
    }
  };
  const handleBillingaddressSelection = () => {
    if (selectedAddressId === null) {
      // Disable billing address checkbox if no shipping address is selected
      setSameForBillingAddressChecked(false);
    } else {
      setSameForBillingAddressChecked(!sameForBillingAddressChecked); // Toggle the checkbox
    }
  };

  // console.log(customAmount);
  const [sevaProducts, setSevaProducts] = useState([]);
  // console.log(sevaProducts);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await instance.get(
          `puja/seva/products?seva_id=${seva_id}`
        );
        setSevaProducts(response.data);
        // console.log(sevaProducts);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchProducts();
  }, [seva_id]);

  const [counter, setCounter] = useState({});

  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [showDecrement, setShowDecrement] = useState({}); //recent add
  const incrementCounter = (productId) => {
    const newCounter = { ...counter };
    if (newCounter[productId] === undefined) {
      newCounter[productId] = 0;
    }
    newCounter[productId]++;
    setCounter(newCounter);
    setShowDecrement({ ...showDecrement, [productId]: true }); //recent add
    updateTotalPrice(productId, newCounter[productId]);
    updateSelectedProducts(productId, newCounter[productId]);
  };

  const decrementCounter = (productId) => {
    const newCounter = { ...counter };
    if (newCounter[productId] !== undefined && newCounter[productId] > 0) {
      newCounter[productId]--;
      setCounter(newCounter);

      if (newCounter[productId] === 0) {
        //recent add
        setShowDecrement({ ...showDecrement, [productId]: false }); //recent add
      } //recent add

      updateTotalPrice(productId, newCounter[productId]);
      updateSelectedProducts(productId, newCounter[productId]);
    }
  };

  const updateTotalPrice = (productId, count) => {
    const product = sevaProducts.find(
      (item) => item.product_item.id === productId
    );
    if (product) {
      const productPrice = parseFloat(product.product_item.price);
      const totalPriceChange =
        productPrice * (count - (counter[productId] || 0));
      setTotalPrice((prevTotalPrice) => prevTotalPrice + totalPriceChange);
    }
  };

  const updateSelectedProducts = (productId, count) => {
    const product = sevaProducts.find(
      (item) => item.product_item.id === productId
    );
    if (product) {
      const existingProductIndex = selectedProducts.findIndex(
        (item) => item.seva_product === productId
      );
      const updatedProducts = [...selectedProducts];
      if (existingProductIndex !== -1) {
        updatedProducts[existingProductIndex].quantity = count;
      } else {
        updatedProducts.push({ seva_product: productId, quantity: count });
      }
      setSelectedProducts(updatedProducts);
    }
  };
  // console.log(selectedProducts);

  // console.log(totalPrice);

  const sendPaymentStatusToServer = async (data, response, status) => {
    try {
      if (status == "Success") {
        await instance.put(`/customer/order/${data.data.id}/update_status/`, {
          payment_status: status,
          transaction_id: response.razorpay_payment_id,
          transaction_response: JSON.stringify(data.data.razorpay_order),
        });

        let total_amount = (
          parseFloat(selectedSevaPrice) +
          (parseFloat(selectedAmount) || 0) +
          (totalPrice > 0 ? parseFloat(totalPrice) : 0) +
          parseFloat(extraChargeAmount)
        ).toFixed(2);
        console.log(total_amount);
        localStorage.setItem("payment_amount_seva", total_amount);

        setThankYouseva(true);
        navigate(`/Thankyou_seva`, {
          state: {
            transaction_id: response.razorpay_payment_id,
            page: "seva",
          },
        });
        setCheckoutSuccess(true);
        setShowModal1(true);
      } else if (status == "Cancelled") {
        await instance.put(`/customer/order/${data.data.id}/update_status/`, {
          payment_status: status,
          transaction_id: data.data.invoice_id,
          transaction_response: JSON.stringify(data.data.razorpay_order),
        });
      } else if (response.error.reason == "payment_failed") {
        await instance.put(`/customer/order/${data.data.id}/update_status/`, {
          payment_status: status,
          transaction_id: response.error.metadata.payment_id,
          transaction_response: JSON.stringify(data.data.razorpay_order),
        });
      }
    } catch (error) {
      console.error("Error sending payment status to server", error);
    }
  };
  // console.log(isIndian);
  const handleCheckout = async () => {
    try {
      // console.log(customAmount);
      fetchExpire();
      const atoken = localStorage.getItem("vedatoken");
      const token = isTokenExpired(atoken);
      console.log(token);
      if (token === true) {
        localStorage.clear();
        navigate("/");
        setIsLoggedIn(false);
        toast.error("Session expired. Please Login again.");
        return;
      }
      console.log(expire);

      if (expire === true) {
        toast.error("Donation has expired. Please select another donation.");
        navigate("/");
        return;
      }
      let shippingAddressId = selectedAddressId;

      if (sameForBillingAddressChecked) {
        shippingAddressId = selectedAddressId;
      } else {
        shippingAddressId = selectedAddressId1;
      }
      console.log(selectedSeva);
      console.log(selectedAmount);

      const requestData = {
        seva_type: Number(sevaprice_id),
        seva_id: Number(selectedSeva.seva.id),
        puja_id: Number(selectedPuja.id),
        dakshana_amount: selectedAmount ? Number(selectedAmount) : 0,
        family_ids: selectedFamilyMembers.map((person) => person.id),
        billing_address: shippingAddressId,
        shipping_address: selectedAddressId,
        products: selectedProducts,
        extra_amount: Number(extraChargeAmount),
        personal_details: {
          firstName: firstName,
          lastName: lastName,
          email: emailprofile,
          mobileNo: mobileNo,
          address: address,
          // pan: panNo,
          // isIndian: isIndian ? "true" : "false",
        },
        want_prasadam: wantPrasadam,
      };
      new GTM().initiateCheckout({
        email: requestData.personal_details.email,
        totalAmount: selectedAmount ? Number(selectedAmount) : 0,
        sevaId: seva_id,
        slug: slug,
        quantity: selectedProducts.length || 1,
        name: selectedSeva.seva.name,
        products: selectedProducts,
      });
      console.log(requestData);

      const { data } = await instance.post(
        "customer/order/create/",
        requestData
      );
      console.log(data.data.personal_details);

      const personalDetailsString = data.data.personal_details.replace(
        /'/g,
        '"'
      );

      let UserData = JSON.parse(personalDetailsString);

      console.log("Order created successfully:", data);
      console.log("Order created successfully:", data.razorpay_order.amount);
      localStorage.setItem(
        "payment_amount_seva",
        data.razorpay_order.amount / 100
      );
      localStorage.setItem("transactionId", data.razorpay_order.id);
      const options = {
        key: data.razorpay_order.RAZORPAY_API_KEY,
        amount: data.razorpay_order.amount,
        currency: data.razorpay_order.currency,
        name: "Veda Sankalpa",
        description: "Total Amount",
        image: { logoIcon },
        order_id: data.razorpay_order.id,
        handler: function (response) {
          console.log("response", response);
          sendPaymentStatusToServer(data, response, "Success");
        },
        modal: {
          ondismiss: function () {
            sendPaymentStatusToServer(data, null, "Cancelled");
          },
        },
        prefill: {
          name: UserData.firstName,
          email: UserData.email,
          contact: UserData.mobileNo,
        },
        // notes: {
        // 	address: deliverAddress,
        // },
        redirect: true,

        callback_url:
          "https://prod.backend.vedasankalpa.com/v1/customer/order/server_update/",

        //   "https://backend.vedasankalpa.com/v1/customer/order/server_update/",
        retry: {
          enabled: false,
        },
        timeout: 900,
        theme: {
          color: "#c84204",
        },
      };
      const razor = new window.Razorpay(options);
      razor.on("payment.failed", function (response) {
        sendPaymentStatusToServer(data, response, "Failed");
      });
      razor.open();
      // setCheckoutSuccess(true);
      // setShowModal1(true); //
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("Error creating order:", error);
      // Handle errors (e.g., show error message to user)
    }
  };
  // console.log(checkoutSuccess);
  useEffect(() => {
    if (checkoutSuccess && !showModal1) {
      navigate("/");
    }
  }, [checkoutSuccess, showModal1, sameForBillingAddressChecked]);

  const subtotal = parseFloat(selectedSevaPrice);

  let extraChargeAmount = 0;

  if (selectedSeva && selectedSeva.seva.extra_charge) {
    const extraChargeType = selectedSeva.seva.extra_charge.charge_type;
    const extraChargeValue = parseFloat(
      selectedSeva.seva.extra_charge.charge_value
    );

    if (extraChargeType === "Percentage") {
      extraChargeAmount = (extraChargeValue / 100) * subtotal;
    }
  }

  return (
    <>
      <Header />
      <div className="sevas_checkout">
        <div className="top-stepper checout_top">
          <Container>
            <div className="checkout_title">
              <h2>Checkout</h2>
              {/* <p>
              Proceed to payment and checkout after filling the required details
              and selecting your payment method.
            </p> */}
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      disabled={index !== activeStep}
                      onClick={handleStepClick(index)}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="top-stepper">
              {steps.map((label, index) => (
                <div
                  key={label}
                  style={{ display: index === activeStep ? "block" : "none" }}
                >
                  {index === 0 && (
                    <div className="check_out_1">
                      <div className="check_out_content">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={8}>
                            {/* <div className="mycart_title">
                            <h3>Checkout</h3>
                          </div> */}
                            <div className="mycart_bg">
                              <div className="mycart_bg_seva_name">
                                <div className="mycart_bg_seva_name_list">
                                  {selectedSeva?.seva?.image ? (
                                    <div className="mycart_bg_seva_name_list_item">
                                      <img
                                        src={`${selectedSeva?.seva?.image?.base_url}${selectedSeva?.seva?.image?.image_path}`}
                                        alt="Banner"
                                      />
                                    </div>
                                  ) : null}
                                  <div className="mycart_bg_seva_name_list_item_1">
                                    {selectedSeva && selectedSeva.seva && (
                                      <>
                                        <h3>{selectedSeva.seva.name}</h3>
                                        {/* <h4>
                                          {formatDate(selectedPuja.start_date)}
                                        </h4>
                                        <h5>
                                          Location -{" "}
                                          <span>{selectedPuja.location}</span>
                                        </h5> */}
                                        {selectedSevaPrice ? (
                                          // <p>₹{selectedSevaPrice}</p>
                                          <p>
                                            ₹
                                            {Intl.NumberFormat(
                                              "en-IN",
                                              options
                                            ).format(
                                              Math.round(selectedSevaPrice)
                                            )}
                                          </p>
                                        ) : (
                                          <p>Loading...</p>
                                        )}
                                      </>
                                    )}
                                    <div className="mycart_remove mb_mycart_remove">
                                      <Button onClick={handleRemoveClick}>
                                        Back
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="mycart_remove">
                                  <Button onClick={handleRemoveClick}>
                                    Back
                                  </Button>
                                </div>
                              </div>
                              {/* <h6 style={{borderBottom:"2px solid #F49004",margin:"10px 0px 10px 0px",width:"100%"}} ></h6> */}
                              <div className="mycart_bg_seva_name cart_menu">
                                <div className="mycart_bg_seva_name_list">
                                  <div className="mycart_bg_seva_name_list_item_1 prasadam_donate">
                                    {selectedSeva && selectedSeva.seva && (
                                      <>
                                        {selectedSeva.seva.prasad_available ? (
                                          <div className="prasadam_content">
                                            <Checkbox
                                              onChange={() => handleprasadam()}
                                              checked={wantPrasadam}
                                            />{" "}
                                            <span className="prasadam_content_span">
                                              {" "}
                                              Add Prasadam
                                            </span>
                                          </div>
                                        ) : null}

                                        {/* <h4>
                                          {formatDate(selectedPuja.start_date)}
                                        </h4>
                                        <h5>
                                          Location -{" "}
                                          <span>{selectedPuja.location}</span>
                                        </h5>
                                        {selectedSevaPrice ? (
                                          <p>₹{selectedSevaPrice}</p>
                                        ) : (
                                          <p>Loading...</p>
                                        )} */}
                                      </>
                                    )}
                                  </div>
                                </div>
                                {/*<div className="mycart_remove">
                                  <Button onClick={handleRemoveClick}>
                                    Remove
                                  </Button>
                                </div>*/}
                                <div className="product_sm">
                                  {sevaProducts && sevaProducts.length > 0 && (
                                    <div className="summary-bg Product_bg">
                                      <p className="summary_title">Product</p>
                                      {sevaProducts.map((item) => (
                                        <div
                                          className="product_item"
                                          key={item.id}
                                        >
                                          {item.product_item.image ? (
                                            <div className="product_list">
                                              <img
                                                src={`${item.product_item.image.base_url}${item.product_item.image.image_path}`}
                                                alt={item.product_item.name}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="product_list_content">
                                            <h3>{item.product_item.name}</h3>
                                            <p>
                                              {item.product_item.description}
                                            </p>
                                            <div className="ganga_jal_cost">
                                              <div className="">
                                                <p>
                                                  ₹{item.product_item.price}
                                                </p>
                                              </div>
                                              <div className="ganga_jal_cost_button">
                                                {/* <Button className="minus_btn"
                                            onClick={() =>
                                              decrementCounter(
                                                item.product_item.id
                                              )
                                            }
                                          >
                                            -
                                          </Button>
                                          <span>
                                            {counter[item.product_item.id] || 0}
                                          </span>
                                          <Button className="plus_btn"
                                            onClick={() =>
                                              incrementCounter(
                                                item.product_item.id
                                              )
                                            }
                                          >
                                            +
                                          </Button> */}
                                                {/* recent add */}
                                                {showDecrement[
                                                  item.product_item.id
                                                ] ? (
                                                  <>
                                                    <Button
                                                      className="minus_btn"
                                                      onClick={() =>
                                                        decrementCounter(
                                                          item.product_item.id
                                                        )
                                                      }
                                                    >
                                                      -
                                                    </Button>
                                                    <span>
                                                      {
                                                        counter[
                                                          item.product_item.id
                                                        ]
                                                      }
                                                    </span>
                                                    <Button
                                                      className="plus_btn"
                                                      onClick={() =>
                                                        incrementCounter(
                                                          item.product_item.id
                                                        )
                                                      }
                                                    >
                                                      +
                                                    </Button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Button
                                                      className="puja_btn"
                                                      onClick={() =>
                                                        incrementCounter(
                                                          item.product_item.id
                                                        )
                                                      }
                                                    >
                                                      Add
                                                    </Button>
                                                    <Button
                                                      className="plus_btn"
                                                      onClick={() =>
                                                        incrementCounter(
                                                          item.product_item.id
                                                        )
                                                      }
                                                    >
                                                      +
                                                    </Button>
                                                  </>
                                                )}
                                                {/* recent add */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="dakshana_bg dakshana_bg_select checkbox_dakshana">
                                {/* <h3>Dakshana</h3> */}
                                <div className="dakshana-select dakshana_check_select">
                                  {/* <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Select Dakshana Amount
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          label="Select Dakshana Amount"
                                          value={selectedDakshana}
                                          onChange={handleChangeDaskshana}
                                        >
                                          {dakshanaOptions.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option.id}
                                                value={option.value}
                                              >
                                                ₹{option.value}
                                              </MenuItem>
                                            );
                                          })}
                                          <MenuItem value="other">Other</MenuItem>
                                        </Select>
                                      </FormControl> */}
                                  {/* <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={customAmountChecked}
                                        onChange={handleCustomAmountCheckChange}
                                      />
                                    }
                                    label="Dakshana Amount"
                                  /> */}
                                  {/* <div className="tip_amount_details_text">
                                    Your tip will help us support more stakeholders
                                    of Sanatana Dharma. Giving is Godly!
                                  </div> */}
                                </div>
                                {/* {selectedDakshana === "other" && (
                                          <div className="custom-amount-input tip_amount">
                                            <div className="amount-input_inputWrapper">
                                              <span className="rupee-symbol">₹</span>
                                              <TextField
                                                label="Enter amount"
                                                type="number"
                                                value={customAmount}
                                                onChange={handleCustomAmountChange}
                                              />
                                            </div>
                                          </div>
                                        )} */}
                                {customAmountChecked && (
                                  <div className="custom-amount-input tip_amount">
                                    <div className="amount-input_inputWrapper">
                                      <span className="rupee-symbol">₹</span>
                                      <TextField
                                        label="Enter amount"
                                        type="number"
                                        value={customAmount}
                                        onChange={handleCustomAmountChange}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>

                              <Paper elevation={0} className="card-box">
                                <Accordion
                                  className="personal-info"
                                  expanded={expanded}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        onClick={() => handleExpansion()}
                                      />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <div className="personal_info_check_out">
                                      Personal Information
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="personal_information_form">
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            label="First Name *"
                                            variant="outlined"
                                            value={firstName}
                                            error={error?.first}
                                            onChange={(e) =>
                                              updateName(
                                                e.target.value,
                                                "first"
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            label="Last Name *"
                                            variant="outlined"
                                            value={lastName}
                                            error={error?.last}
                                            onChange={(e) =>
                                              updateName(e.target.value, "last")
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            variant="outlined"
                                            value={emailprofile}
                                            error={error?.email}
                                            onChange={(e) =>
                                              updateEmailProfile(e.target.value)
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            label="Mobile No *"
                                            variant="outlined"
                                            disabled={mobileNo ? true : false}
                                            error={error?.mobile}
                                            value={mobileNo}
                                            onChange={(e) =>
                                              updateMobile(e.target.value)
                                            }
                                          />
                                        </Grid>

                                        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                          id="outlined-basic"
                                          label="Pan No"
                                          variant="outlined"
                                          value={panNo}
                                          onChange={(e) =>
                                            setPanNo(e.target.value)
                                          }
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <RadioGroup
                                          className="india_nonindia"
                                          aria-label="indian"
                                          name="indian"
                                          value={
                                            isIndian === null
                                              ? ""
                                              : isIndian
                                              ? "indian"
                                              : "notIndian"
                                          }
                                          onChange={handleIndianChange}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <FormControlLabel
                                            value="indian"
                                            control={<Radio />}
                                            label="Indian"
                                          />
                                          <FormControlLabel
                                            value="notIndian"
                                            control={<Radio />}
                                            label="Not Indian"
                                          />
                                        </RadioGroup>
                                      </Grid> */}
                                      </Grid>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </Paper>
                              {true && (
                                <Paper
                                  elevation={0}
                                  className="card-box"
                                  style={{ marginTop: 30 }}
                                >
                                  <Accordion
                                    className="personal-info"
                                    defaultExpanded={true}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      Add Shipping and Billing Address
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div className="personal-info-main personal-info-main_2">
                                        {/* Display existing addresses for shipping */}
                                        {addresses.length > 0 ? (
                                          addresses.map((address, index) => (
                                            <div
                                              className="personal-info-content"
                                              key={index}
                                            >
                                              <Grid container spacing={2}>
                                                <div className="add-family">
                                                  <div className="add-family-text-1">
                                                    <Checkbox
                                                      checked={
                                                        selectedAddressId ===
                                                        address.id
                                                      }
                                                      onChange={() =>
                                                        handleAddressSelection(
                                                          address.id
                                                        )
                                                      }
                                                    />
                                                    <div>
                                                      <Typography variant="h6">
                                                        {address.address_type}
                                                      </Typography>
                                                      <Typography variant="body1">
                                                        {address.address1},{" "}
                                                        {address.city},{" "}
                                                        {address.state},{" "}
                                                        {address.country}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                  <div className="personal-info-main_edit_button">
                                                    <Button
                                                      onClick={() =>
                                                        setExpandedFormId(
                                                          address.id
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                      <img
                                                        src={edit}
                                                        alt="Edit"
                                                      />
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Grid>
                                              {/* Show form for editing the selected address */}
                                              {expandedFormId ===
                                                address.id && (
                                                <AddressEdit
                                                  addressToEdit={address}
                                                  closeEdit={setExpandedFormId}
                                                />
                                              )}
                                            </div>
                                          ))
                                        ) : (
                                          // If no addresses, automatically show the "Add New Shipping Address" form
                                          <>
                                            <Typography variant="h5">
                                              Add New Shipping Address
                                            </Typography>
                                            <AddressEdit
                                              closeEdit={setExpandedFormId}
                                            />
                                          </>
                                        )}

                                        {/* Always show the Add New Address button */}
                                        <div className="add-address">
                                          {addresses.length > 0 && (
                                            <>
                                              <Typography variant="h5">
                                                Add New Shipping Address
                                              </Typography>
                                              <Button
                                                disableRipple
                                                disableElevation
                                                onClick={() =>
                                                  setExpandedFormId("Add")
                                                }
                                              >
                                                Add
                                                <AddIcon />
                                              </Button>
                                            </>
                                          )}
                                        </div>

                                        {/* Show form if "Add" button is clicked */}
                                        {expandedFormId === "Add" &&
                                        addresses.length > 0 ? (
                                          <AddressEdit
                                            closeEdit={setExpandedFormId}
                                            onAddAddress={handleAddressAddition}
                                          />
                                        ) : null}
                                      </div>

                                      {/* Show "Same for Billing Address" checkbox if shipping address is selected */}
                                      <div className="add_new_1">
                                        {selectedSeva && selectedAddressId && (
                                          <div>
                                            {selectedSeva.seva
                                              .prasad_available && (
                                              <div className="personal-info-content">
                                                <Grid container spacing={2}>
                                                  <div className="add-family">
                                                    <div className="add-family-text-2">
                                                      <Checkbox
                                                        checked={
                                                          sameForBillingAddressChecked
                                                        }
                                                        onChange={
                                                          handleBillingaddressSelection
                                                        }
                                                        disabled={
                                                          selectedAddressId ===
                                                          null
                                                        } // Disable checkbox if no shipping address selected
                                                      />
                                                      <Typography variant="h6">
                                                        Same for Billing Address
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </div>
                                            )}

                                            {!sameForBillingAddressChecked && (
                                              <div className="personal-info-main">
                                                {addresses.map(
                                                  (address, index) => (
                                                    <div
                                                      className="personal-info-content"
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <div className="add-family">
                                                          <div className="add-family-text-1">
                                                            <Checkbox
                                                              onChange={() =>
                                                                handleAddressSelection1(
                                                                  address.id
                                                                )
                                                              }
                                                            />
                                                            <div>
                                                              <Typography variant="h6">
                                                                {
                                                                  address.address_type
                                                                }
                                                              </Typography>
                                                              <Typography variant="body1">
                                                                {
                                                                  address.address1
                                                                }
                                                                , {address.city}
                                                                ,{" "}
                                                                {address.state},{" "}
                                                                {
                                                                  address.country
                                                                }
                                                              </Typography>
                                                            </div>
                                                          </div>
                                                          <div className="personal-info-main_edit_button">
                                                            <Button
                                                              onClick={() =>
                                                                setExpandedFormId(
                                                                  address.id
                                                                )
                                                              }
                                                            >
                                                              Edit
                                                              <img
                                                                src={edit}
                                                                alt="Edit"
                                                              />
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </Grid>
                                                      {expandedFormId ===
                                                        address.id && (
                                                        <AddressEdit
                                                          addressToEdit={
                                                            address
                                                          }
                                                          closeEdit={
                                                            setExpandedFormId
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                                <div className="add-address">
                                                  <Typography variant="h5">
                                                    Add New Billing Address
                                                  </Typography>
                                                  <Button
                                                    disableRipple
                                                    disableElevation
                                                    onClick={() =>
                                                      setExpandedFormId("edit1")
                                                    }
                                                  >
                                                    Add
                                                    <AddIcon />
                                                  </Button>
                                                </div>
                                                {expandedFormId === "edit1" && (
                                                  <AddressEdit
                                                    closeEdit={
                                                      setExpandedFormId
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={4}>
                            <div className="summary-bg">
                              <p className="summary_title">Summary</p>
                              <div className="cost_title">
                                <div className="cost-title">
                                  <p>Sub Total</p>
                                </div>
                                <div className="cost">
                                  <p>₹ {subtotal.toFixed(2)}</p>
                                </div>
                              </div>
                              {totalPrice > 0 && (
                                <div className="cost_title">
                                  <div className="cost-title">
                                    <p>Products</p>
                                  </div>
                                  <div className="cost">
                                    <p>₹{parseFloat(totalPrice).toFixed(2)}</p>
                                  </div>
                                </div>
                              )}
                              {/* {selectedSeva && selectedSeva.seva.extra_charge && (
                              <div className="cost_title">
                                <div className="cost-title">
                                  <p>{selectedSeva.seva.extra_charge.name}</p>
                                </div>
                                <div className="cost">
                                  <p>
                                    {
                                      selectedSeva.seva.extra_charge
                                        .charge_value
                                    }
                                    % (₹{extraChargeAmount.toFixed(2)})
                                  </p>
                                </div>
                              </div>
                            )} */}
                              <div className="cost_title">
                                <div className="cost-title">
                                  <p>Service Charge</p>
                                </div>
                                <div className="cost">
                                  <p>
                                    ₹
                                    {selectedAmount
                                      ? parseFloat(selectedAmount).toFixed(2)
                                      : "0"}
                                  </p>
                                </div>
                              </div>
                              {wantPrasadam ? (
                                <div className="cost_title">
                                  <div className="cost-title">
                                    <p>Delivery Charge</p>
                                  </div>
                                  <div className="cost">
                                    <p>₹0</p>
                                  </div>
                                </div>
                              ) : null}
                              <div className="cost_title">
                                <div className="cost">
                                  <p>Total</p>
                                </div>

                                <div className="cost">
                                  {wantPrasadam ? (
                                    <p>
                                      ₹
                                      {(
                                        parseFloat(selectedSevaPrice) +
                                        (parseFloat(selectedAmount) || 0) +
                                        (totalPrice > 0
                                          ? parseFloat(totalPrice)
                                          : 0) +
                                        0 +
                                        parseFloat(extraChargeAmount)
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    <p>
                                      ₹
                                      {(
                                        parseFloat(selectedSevaPrice) +
                                        (parseFloat(selectedAmount) || 0) +
                                        (totalPrice > 0
                                          ? parseFloat(totalPrice)
                                          : 0) +
                                        parseFloat(extraChargeAmount)
                                      ).toFixed(2)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="product_lg">
                              {sevaProducts && sevaProducts.length > 0 && (
                                <div className="summary-bg Product_bg">
                                  <p className="summary_title">Product</p>
                                  {sevaProducts.map((item) => (
                                    <div className="product_item" key={item.id}>
                                      {item.product_item.image ? (
                                        <div className="product_list">
                                          <img
                                            src={`${item.product_item.image.base_url}${item.product_item.image.image_path}`}
                                            alt={item.product_item.name}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="product_list_content">
                                        <h3>{item.product_item.name}</h3>
                                        <p>{item.product_item.description}</p>
                                        <div className="ganga_jal_cost">
                                          <div className="">
                                            <p>₹{item.product_item.price}</p>
                                          </div>
                                          <div className="ganga_jal_cost_button">
                                            {/* <Button className="minus_btn"
                                            onClick={() =>
                                              decrementCounter(
                                                item.product_item.id
                                              )
                                            }
                                          >
                                            -
                                          </Button>
                                          <span>
                                            {counter[item.product_item.id] || 0}
                                          </span>
                                          <Button className="plus_btn"
                                            onClick={() =>
                                              incrementCounter(
                                                item.product_item.id
                                              )
                                            }
                                          >
                                            +
                                          </Button> */}
                                            {/* recent add */}
                                            {showDecrement[
                                              item.product_item.id
                                            ] ? (
                                              <>
                                                <Button
                                                  className="minus_btn"
                                                  onClick={() =>
                                                    decrementCounter(
                                                      item.product_item.id
                                                    )
                                                  }
                                                >
                                                  -
                                                </Button>
                                                <span>
                                                  {
                                                    counter[
                                                      item.product_item.id
                                                    ]
                                                  }
                                                </span>
                                                <Button
                                                  className="plus_btn"
                                                  onClick={() =>
                                                    incrementCounter(
                                                      item.product_item.id
                                                    )
                                                  }
                                                >
                                                  +
                                                </Button>
                                              </>
                                            ) : (
                                              <>
                                                <Button
                                                  className="puja_btn"
                                                  onClick={() =>
                                                    incrementCounter(
                                                      item.product_item.id
                                                    )
                                                  }
                                                >
                                                  Add
                                                </Button>
                                                <Button
                                                  className="plus_btn"
                                                  onClick={() =>
                                                    incrementCounter(
                                                      item.product_item.id
                                                    )
                                                  }
                                                >
                                                  +
                                                </Button>
                                              </>
                                            )}
                                            {/* recent add */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <div className="back_next_buttons">
                          <div className="back_button">
                            <Button
                              disableRipple
                              disableElevation
                              onClick={handleSlugBack}
                            >
                              <img
                                className="left_sm_arrow"
                                src={left_sm_arrow}
                              />
                              Back
                            </Button>
                          </div>
                          <div className="next_button">
                            <Tooltip
                              title={
                                !firstName || !lastName || !mobileNo
                                  ? "Please fill all required personal fields"
                                  : selectedAddressId === null
                                  ? "Please select a Billing address"
                                  : !sameForBillingAddressChecked
                                  ? "Please select a Same Billing address"
                                  : ""
                              }
                              placement="top"
                              disableHoverListener={
                                firstName &&
                                lastName &&
                                mobileNo &&
                                selectedAddressId !== null &&
                                !sameForBillingAddressChecked
                              }
                            >
                              <span>
                                <Button
                                  disableRipple
                                  disableElevation
                                  onClick={handleNextClick}
                                  disabled={proceedNext}
                                >
                                  <img
                                    className="right_symbol"
                                    src={right_symbol}
                                    alt="Right Symbol"
                                  />
                                  Next
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {index === 1 && (
                    <div className="check_out_1">
                      <div className="check_out_content">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={8}>
                            <div className="mycart_title">
                              <h3>My Cart</h3>
                            </div>
                            <div className="mycart_bg">
                              <div className="mycart_bg">
                                <div className="mycart_bg_seva_name">
                                  <div className="mycart_bg_seva_name_list">
                                    <div className="mycart_bg_seva_name_list_item_1">
                                      {selectedSeva && selectedSeva.seva && (
                                        <>
                                          <h3>{selectedSeva.seva.name}</h3>
                                          <h4>
                                            {formatDate(
                                              selectedPuja.start_date
                                            )}
                                          </h4>
                                          <h5>
                                            Cause -{" "}
                                            <span>{selectedPuja.location}</span>
                                          </h5>
                                          {selectedSevaPrice ? (
                                            <p>₹{selectedSevaPrice}</p>
                                          ) : (
                                            <p>Loading...</p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mycart_remove">
                                    <Button onClick={handleRemoveClick}>
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              {!(sevafor === "None") && (
                                <Accordion
                                  style={{ paddingTop: 20 }}
                                  className="personal-info"
                                  defaultExpanded={true}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    Add Family Member
                                    <Typography variant="body2">
                                      {/* Father I Mother */}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="personal-info-mian personal-info-mian_2">
                                      {familyPersons.map((person, index) => (
                                        <div
                                          className="personal-info-content"
                                          key={index}
                                        >
                                          <Grid container spacing={2}>
                                            <div className="add-family">
                                              <div className="add-family-text">
                                                <Checkbox
                                                  onChange={(e) =>
                                                    handleFamilyMemberSelection(
                                                      e,
                                                      person
                                                    )
                                                  }
                                                />
                                                <Typography variant="h6">
                                                  <span>{person.name}</span>
                                                  {person.relation
                                                    ? `- ${person.relation.name}  `
                                                    : ""}
                                                </Typography>
                                              </div>
                                              <div className="personal-info-mian_edit_buton">
                                                <Button
                                                  onClick={() =>
                                                    setExpandedFamily(person.id)
                                                  }
                                                >
                                                  Edit
                                                  <img src={edit} alt="" />
                                                </Button>
                                              </div>
                                            </div>
                                          </Grid>
                                          {expandedFamily === person.id && (
                                            <AddFamily
                                              personToUpdate={person}
                                              sevafor={sevafor}
                                              close={setExpandedFamily}
                                            />
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="addmore-family">
                                      <Typography variant="h5">
                                        Add more family member{" "}
                                      </Typography>
                                      <Button
                                        disableRipple
                                        disableElevation
                                        onClick={() => setExpandedFamily("Add")}
                                      >
                                        Add
                                        <AddIcon />
                                      </Button>
                                    </div>
                                    {expandedFamily === "Add" && (
                                      <AddFamily
                                        sevafor={sevafor}
                                        close={setExpandedFamily}
                                      />
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={4}>
                            <div className="summary-bg">
                              <p className="summary_title">Summary</p>
                              <div className="cost_title">
                                <div className="cost-title">
                                  <p>Sub Total</p>
                                </div>
                                <div className="cost">
                                  <p>₹ {subtotal.toFixed(2)}</p>
                                </div>
                              </div>
                              {totalPrice > 0 && (
                                <div className="cost_title">
                                  <div className="cost-title">
                                    <p>Products</p>
                                  </div>
                                  <div className="cost">
                                    <p>₹{parseFloat(totalPrice).toFixed(2)}</p>
                                  </div>
                                </div>
                              )}
                              {/* {selectedSeva && selectedSeva.seva.extra_charge && (
                              <div className="cost_title">
                                <div className="cost-title">
                                  <p>{selectedSeva.seva.extra_charge.name}</p>
                                </div>
                                <div className="cost">
                                  <p>
                                    {
                                      selectedSeva.seva.extra_charge
                                        .charge_value
                                    }
                                    % (₹{extraChargeAmount.toFixed(2)})
                                  </p>
                                </div>
                              </div>
                            )} */}
                              <div className="cost_title">
                                <div className="cost-title">
                                  <p>Service Charge</p>
                                </div>
                                <div className="cost">
                                  <p>
                                    ₹
                                    {selectedAmount
                                      ? parseFloat(selectedAmount).toFixed(2)
                                      : "0"}
                                  </p>
                                </div>
                              </div>
                              {wantPrasadam ? (
                                <div className="cost_title">
                                  <div className="cost-title">
                                    <p>Delivery Charge</p>
                                  </div>
                                  <div className="cost">
                                    <p>₹0</p>
                                  </div>
                                </div>
                              ) : null}
                              <div className="cost_title">
                                <div className="cost">
                                  <p>Total</p>
                                </div>

                                <div className="cost">
                                  {wantPrasadam ? (
                                    <p>
                                      ₹
                                      {(
                                        parseFloat(selectedSevaPrice) +
                                        (parseFloat(selectedAmount) || 0) +
                                        (totalPrice > 0
                                          ? parseFloat(totalPrice)
                                          : 0) +
                                        0 +
                                        parseFloat(extraChargeAmount)
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    <p>
                                      ₹
                                      {(
                                        parseFloat(selectedSevaPrice) +
                                        (parseFloat(selectedAmount) || 0) +
                                        (totalPrice > 0
                                          ? parseFloat(totalPrice)
                                          : 0) +
                                        parseFloat(extraChargeAmount)
                                      ).toFixed(2)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="back_next_buttons">
                          <div className="back_button">
                            <Button
                              disableRipple
                              disableElevation
                              onClick={handleprevClick}
                            >
                              <img
                                className="left_sm_arrow"
                                src={left_sm_arrow}
                              />
                              Back
                            </Button>
                          </div>
                          <div className="next_button">
                            <Tooltip
                              title={
                                selectedFamilyMembers.length === 0 &&
                                sevafor !== "None"
                                  ? "Please select at least 1 family member"
                                  : ""
                              }
                              placement="top"
                              disableHoverListener={
                                selectedFamilyMembers.length > 0
                              }
                            >
                              <span>
                                <Button
                                  onClick={handleCheckout}
                                  disableRipple
                                  disableElevation
                                  disabled={
                                    selectedFamilyMembers.length === 0 &&
                                    sevafor !== "None"
                                  }
                                >
                                  <img
                                    className="right_symbol"
                                    src={right_symbol}
                                    alt="Right Symbol"
                                  />
                                  Place Order
                                  <img
                                    className="right_sm_arrow"
                                    src={right_sm_arrow}
                                    alt="Right Small Arrow"
                                  />
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                          {checkoutSuccess && (
                            <Seva_booking_successful openModal={true} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Container>
        </div>
      </div>

      <div className="">
        <Footer />
      </div>
      {/* <a
        href="https://api.whatsapp.com/send?phone=919256585656"
        target="_blank"
        class="btn-whatsapp-pulse btn-whatsapp-pulse-border"
      >
        <img alt="whatsapp" src={whatsapp} />
      </a> */}
    </>
  );
}
export default Check_out;
