import axios from "axios";
const vedatoken = localStorage.getItem("vedatoken");
//let api = "http://localhost:8000/v1/";
let api = "https://prod.backend.vedasankalpa.com/v1/";
//  let api='http://43.205.34.80:91/v1/'
const createAxiosInstance = (vedatoken) => {
  return axios.create({
    baseURL: `${api}/`,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${vedatoken}`,
    },
  });
};
export const instance1 = createAxiosInstance(vedatoken);

export const updateToken = (newToken) => {
  instance1.defaults.headers["Authorization"] = `Bearer ${newToken}`;
};

export const instance = createAxiosInstance(vedatoken);

export const noauthinstance = axios.create({
  baseURL: `${api}/`,
  headers: {
    Accept: "application/json",
  },
});

export default instance1;

const authInstance = axios.create({
  baseURL: api,
  headers: {
    Accept: "application/json",
  },
});

authInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("vedatoken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { authInstance };
