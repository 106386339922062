import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const InfoDialog = ({ title, children, open, onClose }) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>
				{title}

				<IconButton
					edge="end"
					color="inherit"
					onClick={onClose}
					sx={{ position: "absolute", right: 8, top: 8 }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">{children}</Typography>
			</DialogContent>
		</Dialog>
	);
};

export default InfoDialog;