import React, { useEffect, useRef, useState } from "react";
import "../styles/pages/check_out.css";
import "../styles/pages/seva_checkout.css";
import { Grid, Button, Paper } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "react-toastify/dist/ReactToastify.css";
import { authInstance } from "../utils/api";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

export default function AddFamily({ personToUpdate, sevafor, close }) {
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gothram: "",
    relation: "",
    rasi: "",
    nakshatram: "",
  });
  const [relations, setRelations] = useState([]);
  const [rasis, setRasis] = useState([]);
  const [nakshatras, setNakshatras] = useState([]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddFamilyMember = () => {
    // setModalMode("add");
  };

  const handleEditFamilyMember = async (personId) => {
    // const personToUpdate = familyPersons.find(
    //     (person) => person.id === personId
    // );
    if (personToUpdate) {
      const dobDate = personToUpdate.dob ? dayjs(personToUpdate.dob) : null;
      setFormData({ ...personToUpdate, dob: dobDate });

      setFormData((prevFormData) => ({
        ...prevFormData,
        relation: personToUpdate.relation ? personToUpdate.relation.id : "",
        rasi: personToUpdate.rasi ? personToUpdate.rasi.id : "",
        nakshatram: personToUpdate.nakshatram
          ? personToUpdate.nakshatram.id
          : "",
      }));
      // setModalMode("edit");
    }
  };

  const handleModalCancel = () => {
    console.log(formData);
    setFormData({
      name: "",
      dob: "",
      gothram: "",
      relation: "",
      rasi: "",
      nakshatram: "",
    });
    close();
  };

  const handleSave = async () => {
    try {
      if (!formData.name) {
        toast.error("Name is a mandatory field");
        return;
      }
      let dataToSend = {
        name: formData.name,
        family_type: sevafor,
        gothram: formData.gothram,
        relation: formData.relation, // Assuming the backend expects 'relation_id'
        rasi: formData.rasi, // Assuming the backend expects 'rasi_id'
        nakshatram: formData.nakshatram, // Assuming the backend expects 'nakshatram_id'
      };

      if (formData.dob) {
        // Formatting date only if it exists
        const formattedDate = dayjs(formData.dob).format("YYYY-MM-DD");
        dataToSend = { ...dataToSend, dob: formattedDate };
      }
      if (formData.id) {
        const response = await authInstance.patch(
          `customer/family_detail/${formData.id}`,
          dataToSend
        );
      } else {
        const response = await authInstance.post(
          "customer/family_detail",
          dataToSend
        );
      }

      //   fetchFamilyPersonDetails();

      handleModalCancel();
    } catch (error) {
      console.error("Error saving family member:", error);
    }
  };
  const fetchRelations = async () => {
    try {
      const response = await authInstance.get("master/relations/");
      setRelations(response.data);
    } catch (error) {
      console.error("Error fetching relations:", error);
    }
  };

  const fetchRasis = async () => {
    try {
      const response = await authInstance.get("master/rasi/");
      setRasis(response.data);
    } catch (error) {
      console.error("Error fetching rasis:", error);
    }
  };

  const fetchNakshatras = async () => {
    try {
      const response = await authInstance.get("master/nakshatra");
      setNakshatras(response.data);
    } catch (error) {
      console.error("Error fetching nakshatras:", error);
    }
  };

  useEffect(() => {
    fetchRelations();
    fetchRasis();
    fetchNakshatras();
    handleEditFamilyMember();
  }, []);

  return (
    <Paper elevation={0} className="card-box card-box_2">
      <Accordion className="personal-info" expanded={true}>
        <AccordionSummary
          //   expandIcon={<ExpandMoreIcon onClick={() => handleExpansion()} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <div className="personal_info_check_out">
                                      Personal Information
                                    </div> */}
        </AccordionSummary>
        <AccordionDetails>
          <div className="personal-info">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="outlined-basic"
                  label={
                    <>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  variant="outlined"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="date_picker_new">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        // dateFormat="YYYY-MM-DD"
                        format="DD/MM/YYYY"
                        value={formData.dob ? dayjs(formData.dob) : null}
                        onChange={(value) =>
                          setFormData({
                            ...formData,
                            dob: value,
                          })
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="">
                  <TextField
                    id="outlined-basic"
                    label="Gotram"
                    variant="outlined"
                    name="gothram"
                    value={formData.gothram}
                    onChange={handleChange}
                    error={formData.gothram.length > 20}
                    helperText={formData.gothram.length > 20 ? "Gotram cannot exceed 20 characters" : ""}
                    inputProps={{ maxLength: 20 }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Relation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.relation}
                      onChange={handleChange}
                      name="relation"
                      label="Relation"
                    >
                      {relations.map((relation) => (
                        <MenuItem key={relation.id} value={relation.id}>
                          {relation.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Nakshatram
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.nakshatram}
                      onChange={handleChange}
                      name="nakshatram"
                      label="Nakshatram"
                    >
                      {nakshatras.map((nakshatra) => (
                        <MenuItem key={nakshatra.id} value={nakshatra.id}>
                          {nakshatra.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Rasi</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.rasi}
                      onChange={handleChange}
                      name="rasi"
                      label="Rasi"
                    >
                      {rasis.map((rasi) => (
                        <MenuItem key={rasi.id} value={rasi.id}>
                          {rasi.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                  <div className="">
                                    <TextField
                                      id="outlined-basic"
                                      label="Notes"
                                      variant="outlined"
                                      name="notes"
                                      value={formData.notes}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Grid> */}
            </Grid>
          </div>

          <div className="rest_save_button">
            <Button
              disableRipple
              disableElevation
              className="rest"
              onClick={handleModalCancel}
            >
              Cancel
            </Button>
            <Button
              disableRipple
              disableElevation
              className="save"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
