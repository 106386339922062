import React,{useEffect} from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Explore_puja from "./pages/Explore_puja";
import Details_puja from "./pages/Details_puja";
import Details_donation from "./pages/Details_donation";
import Check_out from "./pages/Check_out";
import Privacy_policy from "./pages/Privacy_policy";
import Privacy_policy_VDS from "./pages/Privacy_policyVDS";
import Terms_conditions from "./pages/Terms_conditions";
import Terms_conditions_VDS from "./pages/Terms_conditionsVDS";
import Cancellation_policy from "./pages/Cancellation_policy";
import Cancellation_policy_VDS from "./pages/Cancellation_policyVDS";
import ScrollToTop from '../src/ScrollToTop'
import Profile from "./pages/Profile";
import Profile_details from "./components/Profile_details";
import Myfamily_details from "./components/Myfamily_details";
import Karta_details from "./components/Karta_details";
import Karta_ancestor_details from "./components/Karta_ancestor_details";
import Ancestor_details from "./components/Ancestor_details";
import Address from "./components/Address";
import Order_transaction from "./components/Order_transaction";
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Donation_checkout from "./pages/Donation_checkout";
import GDownload from "./components/GDownload";
import About_us from "./pages/About_us";import About_us_VDS from "./pages/About_usVDS";
import ThankYou from "./pages/ThankYou";
import Cancelled from "./pages/Cancelled";
import ThankYou_Seva from "./pages/ThankYou_Seva";
import ReactGA from "react-ga4";
import Faqs from "./pages/Faqs";
import Error from "./pages/Error";



function App() {
  useEffect(() => {
    ReactGA.initialize("G-KQ2ZREB2HM");
    ReactGA.send("pageview");
  }, []);

  return (
    <>
    <ToastContainer />
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/error" element={<Error/>}/>
      <Route path="/about-us" element={<About_us/>}/>    <Route path="/about-us-VDS" element={<About_us_VDS/>}/>
      <Route path="/exploreall" element={<Explore_puja/>}/>
      <Route path='/pujas/:slug' element={<Details_puja />} />
      <Route path='/donation/:slug' element={<Details_donation/>}/>
      {/* <Route path='/checkout' element={<Check_out/>}/> */}
      <Route path='/checkout/:slug/:seva_id/:sevaprice_id' element={<Check_out/>}/>
      <Route path='/checkout/:slug/:donate_id' element={<Donation_checkout/>}/>
      <Route path='/privacy-policy' element={<Privacy_policy/>}/>
      <Route path='/terms-conditions' element={<Terms_conditions/>}/>
      <Route path='/cancellation-policy' element={<Cancellation_policy/>}/>
      <Route path='/privacy-policy-VDS' element={<Privacy_policy_VDS/>}/>
      <Route path='/terms-conditions-VDS' element={<Terms_conditions_VDS/>}/>
      <Route path='/cancellation-policy-VDS' element={<Cancellation_policy_VDS/>}/>
      <Route path="/Thankyou" element= {<ThankYou/>}/>
      <Route path="/faqs" element={<Faqs/>}/>
      <Route path="/Thankyou_seva" element={<ThankYou_Seva/>}/>
      <Route path="/fail" element= {<Cancelled/>}/>
      <Route path='/profile' element={<Profile/>}>
      <Route path="/profile/myprofile" element={<Profile_details/>}/>
      <Route path="/profile/myfamily" element={<Myfamily_details/>}/>
      <Route path="/profile/kartha" element={<Karta_details/>}/>
      <Route path="/profile/ancestor" element={<Ancestor_details/>}/>
      <Route path="/profile/kartha-ancestor" element={<Karta_ancestor_details/>}/>
      <Route path="/profile/address" element={<Address/>}/>
      <Route path="/profile/ordertrans" element={<Order_transaction/>}/>
      <Route path="/profile/Gdownload" element={<GDownload/>}/>
      
      </Route>
    </Routes>
    <ScrollToTop />
    </>
  );
}

export default App;
