import React, { useEffect, useState } from "react";
import "../styles/pages/check_out.css";
import "../styles/pages/seva_checkout.css";
import { Grid, Button, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "react-toastify/dist/ReactToastify.css";
import { authInstance } from "../utils/api";

export default function AddressEdit({ addressToEdit, closeEdit }) {
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [addressType, setAddressType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [expanded, setExpanded] = useState(true);

  // Function to pre-fill the form with addressToEdit details
  const handleEditAddress = () => {
    if (addressToEdit) {
      setFullName(addressToEdit.full_name || "");
      setMobileNumber(addressToEdit.phone_no || "");
      setWhatsappNo(addressToEdit.whatsup_no || "");
      setEmail(addressToEdit.email || "");
      setAddressLine1(addressToEdit.address1 || "");
      setAddressLine2(addressToEdit.address2 || "");
      setPinCode(addressToEdit.pincode || "");
      setAddressType(addressToEdit.address_type || "");
      setSelectedCountry(addressToEdit.country || "");
      setSelectedState(addressToEdit.state || "");
      setSelectedCity(addressToEdit.city || "");
      setEditingAddressId(addressToEdit.id);
    }
  };

  useEffect(() => {
    handleEditAddress(); // Pre-fill the form
  }, [addressToEdit]);

  // Function to save or update the address
  const handleSaveAddress = async () => {
    try {
      if (
        !fullName ||
        !mobileNumber ||
        !addressLine1 ||
        !pinCode ||
        !selectedCity ||
        !selectedState||
        !selectedCountry
        // !whatsappNo ||
       // !addressType
    ) {
        toast.error("Please fill in all mandatory fields.")
        return
    }
      const dataToSend = {
        full_name: fullName || "", // Send empty string if no value
        phone_no: mobileNumber || "", // Send empty string if no value
        whatsup_no: whatsappNo || "", // Optional field
        email: email || "", // Optional field
        address1: addressLine1 || "", // Send empty string if no value
        address2: addressLine2 || null, // Optional field
        pincode: pinCode || "", // Send empty string if no value
        address_type: addressType || "", // Send empty string if no value
        city: selectedCity || "", // Send empty string if no value
        state: selectedState || "", // Send empty string if no value
        country: selectedCountry || "", // Send empty string if no value
      };

      let response;
      if (editingAddressId) {
        response = await authInstance.patch(
          `customer/address/${editingAddressId}`,
          dataToSend
        );
      } else {
        response = await authInstance.post("customer/address", dataToSend);
      }

      toast.success("Address saved successfully.");
      clearForm();
      closeEdit(""); // Close the form after saving
    } catch (error) {
      console.error("Error saving/updating address:", error);
      toast.error("Error saving address. Please try again later.");
    }
  };

  const clearForm = () => {
    setFullName("");
    setMobileNumber("");
    setEmail("");
    setAddressLine1("");
    setAddressLine2("");
    setPinCode("");
    setWhatsappNo("");
    setAddressType("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
  };

  const handleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper elevation={0} className="card-box card-box_2">
      <Accordion className="personal-info" expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => handleExpansion()} />}
          aria-controls="panel1-content"
          id="panel1-header"
        />
        <AccordionDetails>
          <div className="personal_information_form">
            <div className="personal-info">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="fullName"
                    label="Full Name *"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="mobileNumber"
                    label="Mobile Number *"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="email"
                    label="Email "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="whatsappNo"
                    label="Alternate No"
                    value={whatsappNo}
                    onChange={(e) => setWhatsappNo(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine1"
                    label="Address Line 1 *"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine2"
                    label="Address Line 2"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="country"
                    label="Country *"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="state"
                    label="State *"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="city"
                    label="City *"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="pinCode"
                    label="Pin Code *"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel id="addressType-label">Address Type</InputLabel>
                    <Select
                      labelId="addressType-label"
                      id="addressType-select"
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      variant="outlined"
                      label="Address Type"
                    >
                      <MenuItem value="">Select Address Type</MenuItem>
                      <MenuItem value="Home">Home</MenuItem>
                      <MenuItem value="Work">Work</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className="rest_save_button">
              <Button
                disableRipple
                disableElevation
                className="rest"
                onClick={clearForm}
              >
                Discard
              </Button>
              <Button
                disableRipple
                disableElevation
                className="save"
                onClick={handleSaveAddress}
              >
                Save Address
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
